 // web only style
 .ng5-slider {
     z-index: 0 !important;
 }

 .arabic-css {
     direction: rtl !important;
 }

 .loading-spinner {
     -webkit-animation: rotation 2s infinite linear;
     animation: rotation 2s infinite linear;
 }

 @keyframes rotation {
     from {
         transform: rotate(0deg);
     }

     to {
         transform: rotate(359deg);
     }
 }

 @-webkit-keyframes rotation {
     from {
         -webkit-transform: rotate(0deg);
     }

     to {
         -webkit-transform: rotate(359deg);
     }
 }

 .hotelPopup{
    cursor:pointer;
    background-color:white;
    z-index:10;
    padding: 10px;
    box-shadow: 3px 4px 4px #0000000a;

    &:hover{
        background-color:#003a50;
        color:white;
        z-index:22 !important;
    }

    .hotelPrice{
        white-space:nowrap;
    }

    .hotelDetails {
        padding: 18px;
        height: 100%;
        width: 15rem;
        background-color: inherit;
        display: none;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;

        .hotelImage {
            margin-bottom: 15px;
            height: 145px;
            width: 200px;
            object-fit: contain;
            background-image: url('/assets/algerie-booking/citrine/images/')
        }

        .hotelInfo {
            list-style: none;
            padding-left: 15px;
        }

        .hotelName{
            font-size: large;
            text-align: center;
            font-weight: 700;
        } 
       
    }
}

.hotelPopup .leaflet-popup-content-wrapper {
    background:transparent;
    color:inherit;
    box-shadow:none;
}

.hotelPopup .leaflet-popup-content {
    text-align:inherit;
    color:inherit;
    margin:0px;
    width: 100% !important;
}

.hotelPopup .leaflet-popup-tip-container{
    display:none
}

.algebratecTable { 
    border-collapse: initial !important;
    border-spacing: 0 12px !important;

    thead > tr > th {
        vertical-align: middle;
    }
}